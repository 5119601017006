<template>
  <div class="shakeContainer">
    <div class="titleWrap">
      <img src="@/assets/images/shake/title.png" alt />
    </div>
    <div class="shakeCircle">
      <img src="@/assets/images/shake/circle.png" alt />
    </div>
    <div class="btWrap">
      <span v-if="liveGame.status == 0">未开始</span>
      <span v-else>已提交{{ score }}分</span>
    </div>
    <div class="tipWrap">
      <img src="@/assets/images/shake/tip.png" alt />
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
// import { parseQuery } from "@utils";
import { mapGetters } from "vuex";
// 定义一个摇动的阈值:为了防止正常移动的误判，需要给该变化率设置一个合适的临界值
let shakeThreshold = 500;
// 记录上一次摇动的时间
let lastUpdate = 0;
// 定义x、y、z记录三个轴的数据以及上一次触发的数据
let x, y, z, lastX, lastY, lastZ;
export default {
  name: "index",
  data() {
    return {
      liveGameUserId: null,
      score: 0,
      threshold: 0,
      liveGameInfo:{}
    };
  },
  computed: mapGetters(["liveGame"]),
  mounted() {
    //初始化
    this.liveGameUserId = this.$route.query.liveGameUserId;
    Dialog.alert({
      message: "点击授权访问设备",
    }).then(() => {
      this.initGrand();
      this.$store.dispatch('liveGame/getLiveGame',this.liveGameUserId).then(res=>{
        this.liveGameInfo = res
      });
    });
  },
  methods: {
    initGrand() {
      // 监听传感器运动事件
      if (!window.DeviceMotionEvent) {
        this.$toast.fail("您的设备不支持摇一摇游戏！");
        return;
      }
      let _this = this;
      if (typeof DeviceMotionEvent.requestPermission === "function") {
        // IOS13 设备
        DeviceMotionEvent.requestPermission()
          .then((permissionState) => {
            if (permissionState === "granted") {
              window.addEventListener("devicemotion", this.deviceMotionHandler);
            }
          })
          .catch((err) => {
            console.error(err);
            this.$toast.fail(err);
          });
      } else {
        // 其他支持加速度检测的系统
        let timer = setTimeout(function () {
          _this.$toast.fail('用户未开启权限!');
        }, 1000);
        window.addEventListener(
          "devicemotion",
          () => {
            clearTimeout(timer);
          },
          { once: true }
        );
        window.addEventListener("devicemotion", this.deviceMotionHandler);
      }
    },
    deviceMotionHandler(e) {
      // 获取含重力的加速度
      let acceleration = e.accelerationIncludingGravity;
      let curTime = Date.now();
      // 100毫秒进行一次位置判断
      if (curTime - lastUpdate > 100) {
        // this.$dialog.alert({ mes: "摇动!" });
        let diffTime = curTime - lastUpdate;
        lastUpdate = curTime;
        x = acceleration.x;
        y = acceleration.y;
        z = acceleration.z;
        let speed =
          (Math.abs(x + y + z - lastX - lastY - lastZ) / diffTime) * 10000;
        // 前后x, y, z间的差值的绝对值和时间比率超过了预设的阈值，则判断设备进行了摇晃操作
        if (speed > shakeThreshold) {
          this.threshold = this.threshold + 1;
          let param = {
            id: this.liveGameUserId,
            score: 1,
          };
          if (this.threshold > 10) {
            if (this.liveGameInfo.status == 0) {
              return;
            }
            this.$http
              .post("/dlr-wapp/liveGame/submitScore", param)
              .then((res) => {
                this.threshold = 0;
                this.score = res.data.score;
                if (res.data.goldCoin > 0) {
                  this.$Bus.$emit("refreshCoin");
                  this.$toast( "抢中" + res.data.goldCoin + "个游戏币！");
                }
              });
          }
        }
        lastX = x;
        lastY = y;
        lastZ = z;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.shakeContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-image: url("~@/assets/images/shake/bg_shake.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .titleWrap {
    width: 337px;
    height: 346px;
    margin-top: 27px;
    //border:1px solid #F3D;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .shakeCircle {
    width: 684px;
    height: 534px;
    margin-top: 32px;
    animation-name: shake;
    animation-delay: 0s;
    animation-duration: 1s; /*动画时间*/
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .btWrap {
    position: absolute;
    bottom: 20%;
    color: #ea3526;
    min-width: 220px;
    text-align: center;
    font-size: 36px;
    font-weight: 800;
    border: 10px solid #f4b409;
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    z-index: 3;
  }
  .tipWrap {
    position: absolute;
    bottom: 5%;
    width: 444px;
    height: 132px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
